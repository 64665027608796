import util from "@/libs/util";
import { _GetDictItemList } from "@/api/admin/dict"; // API
/**
 * dictType 及 getters 命名应和数据字典保持一致
 * 数据库(marital_status) -> Store(maritalStatus)
 */
export default {
  namespaced: true,
  state: {
    dict: [], // 数据字典
  },
  getters: {
    /**
     * @description 返回字典类别
     * @param {*} state vuex state
     */
    dictType(state) {
      return Array.from(
        new Set(state.dict.map((item) => util.toCamel(item.dictType)))
      );
    },
    /**
     * @description 根据名称获取字典
     * @param {*} state vuex state
     */
    getDict: (state) => (ditcName) => {
      return state.dict.filter((item) => {
        return item.dictType === util.toUnderLine(ditcName);
      });
    },
    /**
     * @description 返回性别字典
     * @param {*} state vuex state
     */
    gender(state) {
      return state.dict.filter((item) => {
        return item.dictType === "gender";
      });
    },
    /**
     * @description 返回完善标记字典
     * @param {*} state vuex state
     */
    perfectFlag(state) {
      return state.dict.filter((item) => {
        return item.dictType === "perfect_flag";
      });
    },
    /**
     * @description 返回租户状态字典
     * @param {*} state vuex state
     */
    statusType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "status_type";
      });
    },
    /**
     * @description 返回授权模式字典
     * @param {*} state vuex state
     */
    grantTypes(state) {
      return state.dict.filter((item) => {
        return item.dictType === "grant_types";
      });
    },
    /**
     * @description 返回Quartz任务类型字典
     * @param {*} state vuex state
     */
    jobType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "job_type";
      });
    },
    /**
     * @description 返回Quartz任务运行状态状态字典
     * @param {*} state vuex state
     */
    jobExecuteStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "job_execute_status";
      });
    },
    /**
     * @description 返回Quartz任务状态状态字典
     * @param {*} state vuex state
     */
    jobStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "job_status";
      });
    },
    /**
     * @description 返回Quartz任务错失执行周期字典
     * @param {*} state vuex state
     */
    misfirePolicy(state) {
      return state.dict.filter((item) => {
        return item.dictType === "misfire_policy";
      });
    },
    /**
     * @description 返回密钥类型字典
     * @param {*} state vuex state
     */
    socialType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "social_type";
      });
    },
    /**
     * @description 返回公共参数类型字典
     * @param {*} state vuex state
     */
    paramType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "param_type";
      });
    },
    /**
     * @description 返回公共参数状态字典
     * @param {*} state vuex state
     */
    paramStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "param_status";
      });
    },
    /**
     * @description 返回微信消息回复字典
     * @param {*} state vuex state
     */
    responseType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "response_type";
      });
    },
    /**
     * @description 返回微信订阅状态字典
     * @param {*} state vuex state
     */
    subscribe(state) {
      return state.dict.filter((item) => {
        return item.dictType === "subscribe";
      });
    },
    /**
     * @description 返回请假状态字典
     * @param {*} state vuex state
     */
    leaveStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "leave_status";
      });
    },
    /**
     * @description 返回单位类别字典
     * @param {*} state vuex state
     */
    deptClassify(state) {
      return state.dict.filter((item) => {
        return item.dictType === "dept_classify";
      });
    },
    /**
     * @description 返回单位状态字典
     * @param {*} state vuex state
     */
    deptStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "dept_status";
      });
    },
    /**
     * @description 返回支付类型字典
     * @param {*} state vuex state
     */
    channelId(state) {
      return state.dict.filter((item) => {
        return item.dictType === "channel_id";
      });
    },
    /**
     * @description 返回渠道状态字典
     * @param {*} state vuex state
     */
    channelStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "channel_status";
      });
    },
    /**
     * @description 返回订单支付状态字典
     * @param {*} state vuex state
     */
    orderStatus(state) {
      return state.dict.filter((item) => {
        return item.dictType === "order_status";
      });
    },
    /**
     * @description 返回日志类型字典
     * @param {*} state vuex state
     */
    logType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "log_type";
      });
    },

    /**
     * @description 返回敏感词类别字典
     * @param {*} state vuex state
     */
    sensitiveWordType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "sensitive_word_type";
      });
    },
    /**
     * @description 新闻是否置顶
     * @param {*} state vuex state
     */
    isTopDictType(state) {
      console.log("state", state);
      return state.dict.filter((item) => {
        return item.dictType === "is_top_dict_type";
      });
    },
    /**
     * @description 新闻是否设置焦点
     * @param {*} state vuex state
     */
    isFocusDictType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "is_focus_dict_type";
      });
    },
    /**
     * @description 发布状态
     * @param {*} state vuex state
     */
    publishFlag(state) {
      return state.dict.filter((item) => {
        return item.dictType === "publish_flag";
      });
    },
    /**
     * @description 新闻发布状态
     * @param {*} state vuex state
     */
    publishStatusDictType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "publish_status_dict_type";
      });
    },

    /**
     * @description 政策法规发布部门
     * @param {*} state vuex state
     */
    policyDept(state) {
      return state.dict.filter((item) => {
        return item.dictType === "policy_dept";
      });
    },
    /**
     * @description 政策法规地区
     * @param {*} state vuex state
     */
    policyArea(state) {
      return state.dict.filter((item) => {
        return item.dictType === "policy_area";
      });
    },
    /**
     * @description 投诉问题分类
     * @param {*} state vuex state
     */
    questionType(state) {
      return state.dict.filter(
        (item) => item.dictType == "complaint_question_type"
      );
    },
    /**
     * @description 投诉建议分类
     * @param {*} state vuex state
     */
    complaintType(state) {
      return state.dict.filter((item) => item.dictType == "complaint_type");
    },
    /**
     * @description 家庭成员关系
     * @param {*} state vuex state
     */
    familyRelation(state) {
      return state.dict.filter((item) => {
        return item.dictType === "family_ralation";
      });
    },
    /**
     * @description 交易方式
     * @param {*} state vuex state
     */
    tradingMode(state) {
      return state.dict.filter((item) => {
        return item.dictType === "trading_method";
      });
    },
    tradingMethod(state) {
      return state.dict.filter((item) => {
        return item.dictType === "trading_method";
      });
    },
    /**
     * @description 交易类型
     * @param {*} state vuex state
     */
    complaintSubType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "complaint_sub_type";
      });
    },
    /**
     * @description 交易类别
     * @param {*} state vuex state
     */
    tradingType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "trading_type";
      });
    },

    /**
     * @description 交易状态
     * @param {*} state vuex state
     */
    tradeStatu(state) {
      return state.dict.filter((item) => {
        return item.dictType === "trade_status";
      });
    },

    /**
     * @description 承包方式
     * @param {*} state vuex state
     */
    contractmethod(state) {
      return state.dict.filter((item) => {
        return item.dictType === "contract_method";
      });
    },
    /**
     * @description 质量等级
     * @param {*} state vuex state
     */
    landlevel(state) {
      return state.dict.filter((item) => {
        return item.dictType === "land_level";
      });
    },

    familyrelation(state) {
      return state.dict.filter((item) => {
        return item.dictType === "family_relation";
      });
    },

    /**
     * @description 是否基本农田
     * @param {*} state vuex state
     */
    landbasic(state) {
      return state.dict.filter((item) => {
        return item.dictType === "land_basic";
      });
    },

    /**
     * @description 主体类型
     * @param {*} state vuex state
     */
    subjectType(state) {
      return state.dict.filter((item) => {
        return item.dictType === "subject_type";
      });
    },
  },

  actions: {
    /**
     * init
     * @description  初始化数据字典
     * @param {Object} commit vuex commit
     */
    init({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        // 服务端获取字典
        _GetDictItemList()
          .then((res) => {
            // 持久化
            dispatch(
              "db/set",
              {
                dbName: "sys",
                path: "const.dict",
                value: res,
                user: true,
              },
              {
                root: true,
              }
            );
            //  提交变更
            commit("Set", res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * @description 从持久化数据读取数据字典
     * @param {Object} state vuex commit and dispatch
     */
    load({ commit, dispatch }) {
      return new Promise(async (resolve) => {
        // 给 state.dict 赋值
        commit(
          "Set",
          await dispatch(
            "db/get",
            {
              dbName: "sys",
              path: "const.dict",
              defaultValue: [],
              user: true,
            },
            {
              root: true,
            }
          )
        );
        // end
        resolve();
      });
    },
  },
  mutations: {
    /**
     * Set
     * @description 设置数据字典
     * @param {Object} state vuex state
     * @param {Array}  dict  字典数据
     */
    Set(state, dict) {
      state.dict = dict;
    },
  },
};
