/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 13:30:07
 * @LastEditors: tzw 546419247@qq.com
 * @LastEditTime: 2023-05-09 16:25:50
 */
// import FrontLayout from '@/layout/front/index'
// import BackendLayout from '@/layout/backend/index'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'index'
    },
    component: () => import('@/views/index'),
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/services/productDetail',
    component: () => import('@/views/services/productDetail')
  },
  {
    path: '/register',
    component: () => import('@/views/register')
  },
  // 机构列表
  {
    path: '/services/list',
    component: () => import('@/views/services/list')
  },
  {
    path: '/services/organizatioDetail',
    component: () => import('@/views/services/organizationDetail')
  },
  {
    path: '/services',
    component: () => import('@/views/services')
  },
  {
    path: '/newlist',
    component: () => import('@/views/newlist')
  },
  {
    path: '/newlist/detailed',
    component: () => import('@/views/newlist/detailed')
  },
  {
    name: 'document',
    path: '/newlist/document',
    component: () => import('@/views/newlist/document')
  },
  {
    path: '/newpage',
    component: () => import('@/views/newpage')
  },
  // 竞价大厅
  {
    path: '/bidding',
    component: () => import('@/views/bidding')
  },
  {
    path: '/bidding/competition',
    component: () => import('@/views/bidding/competition')
  },
  {
    path: '/bidding/test',
    component: () => import('@/views/bidding/test')
  },
  {
    path: '/policy/search',
    component: () => import('@/views/policy/search')
  },
  {
    path: '/policy/detail/:id',
    component: () => import('@/views/policy/detail')
  },
  {
    path: '/policy',
    component: () => import('@/views/policy')
  },
  // 找土地列表页
  {
    path: '/findland',
    component: () => import('@/views/findland')
  },
  {
    path: '/findland/findlandList',
    component: () => import('@/views/findland/list')
  },
  // 找土地详情页
  {
    path: '/findland/findlandDetail',
    component: () => import('@/views/findland/detail')
  },
  // 找土地报名页
  {
    path: '/findland/findlandApply',
    component: () => import('@/views/findland/apply')
  },
  // 找土地报名审核页
  {
    path: '/findland/findlandApplyCheck',
    component: () => import('@/views/findland/applycheck')
  },
  //找土地报名上传资料页
  {
    path: '/findland/findlandAppInformation',
    component: () => import('@/views/findland/applyInformation')
  },

  // 找土地报名缴纳保证金
  {
    path: '/findland/findlandApplyMargin',
    component: () => import('@/views/findland/applymargin')
  },
  // 找土地报名成功
  {
    path: '/findland/findlandApplySuccess',
    component: () => import('@/views/findland/applysuccess')
  },
  // 找土地选择稍后支付
  {
    path: '/findland/findlandApplyLater',
    component: () => import('@/views/findland/applylater')
  },
  // 找土地首页
  {
    path: '/findland',
    component: () => import('@/views/findland/index')
  },
  // 金融服务详情页
  {
    path: '/finance/detail',
    component: () => import('@/views/finance/detail')
  },
  {
    path: '/finance',
    component: () => import('@/views/finance/index')
  },
  {
    path: '/business',
    component: () => import('@/views/business')
  },
  {
    path: "/uc",
    component: () => import("@/views/user/layout/layout.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/user/transference/index.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/user/profile.vue"),
      },
      {
        path: "collectionList",
        component: () => import("@/views/user/collectionList.vue"),
      },
      {
        path: "securityCenter",
        component: () => import("@/views/user/securityCenter.vue"),
      },
      {
        path: "message",
        component: () => import("@/views/user/message.vue"),
      },
      // 个人认证
      {
        path: "individual",
        component: () => import("@/views/user/individual.vue"),
      },
      // 企业认证
      {
        path: "enterprise",
        component: () => import("@/views/user/enterprise.vue"),
      },
      {
        path: "entrust",
        component: () => import("@/views/user/entrusts.vue"),
      },
      // 委托方测试页面
      {
        path: "entrustingParty",
        component: () => import("@/views/user/entrustingParty.vue"),
      },
      {
        path: "entustingdetails/detail",
        component: () => import("@/views/user/entustingdetails.vue"),
        name:'/uc/entrustingParty'
      },
      // 我的受让
      {
        path: "transfer",
        component: () => import("@/views/user/transfer.vue"),
      },
      // 我的转让
      {
        path: "transference/detail",
        component: () => import("@/views/user/transference/detail.vue"),
        name: "/uc/transference",
      },
      {
        path: "transference/edit",
        component: () => import("@/views/user/transference/edit.vue"),
        name: "/uc/transference",
      },
      {
        path: "transference/create",
        component: () => import("@/views/user/transference/create.vue"),
        name: "/uc/transference",
      },
      {
        path: "transference",
        component: () => import("@/views/user/transference/index.vue"),
      },
      // 土地产权
      {
        path: "propertyList",
        component: () => import("@/views/user/transference/property/list.vue"),
      },
      {
        path: "propertyDetail",
        component: () =>
          import("@/views/user/transference/property/detail.vue"),
        name: "/uc/propertyList",
      },
      {
        path: "propertyAdd",
        component: () => import("@/views/user/transference/property/add.vue"),
        name: "/uc/propertyList",
      },
      {
        path: "transferdetail",
        component: () => import("@/views/user/transference/transferdetail.vue"),
      },
      {
        path: "order/detail",
        component: () => import("@/views/user/order/detail.vue"),
      },
      {
        path: "order/margin",
        component: () => import("@/views/user/order/margin.vue"),
      },
      {
        path: "order/transference/list",
        component: () => import("@/views/user/order/transference-list.vue"),
      },
      {
        path: "order/transfer/list",
        component: () => import("@/views/user/order/transfer-list.vue"),
      },
      //证书
      {
        path: 'propertyListcard',
        component:()=>import('@/views/user/credential/propertyListcard/index.vue'),

      },
      {
        path: 'propertycard',
        component: () => import('@/views/user/credential/propertyListcard/components/propertycard.vue'),
        name: "/uc/propertyListcard",
      },


      {
        path: 'landmanagementcard',
        component:()=>import('@/views/user/credential/landmanagementcard/index.vue'),
      },
      {
        path: 'lancard',
        // component:()=>import('@/views/user/credential/lancard/index.vue'),
        // component: () => import('@/views/user/credential/propertyListcard/components/lancard.vue'),
      }
    ],
  },
  // {
  //   path: '/uc',
  //   redirect: { name: 'home' },
  //   component: BackendLayout,
  //   children: [
  //     // 首页
  //     {
  //       path: 'home',
  //       name: 'home',
  //       component: () => import('@/views/backend/index')
  //     },
  //     // 会员资料
  //     {
  //       path: 'user',
  //       name: 'user',
  //       component: () => import('@/views/backend/userInfo')
  //     },
  //     // 修改密码
  //     {
  //       path: 'safe',
  //       name: 'safe',
  //       component: () => import('@/views/backend/userSafe')
  //     },
  //     // 手机换绑
  //     {
  //       path: 'mobile',
  //       name: 'mobile',
  //       component: () => import('@/views/backend/userMobile')
  //     },
  //     // 消息列表
  //     {
  //       path: 'message',
  //       name: 'message',
  //       component: () => import('@/views/backend/userMessage')
  //     }
  //   ]
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login/index'),
  //   meta: {
  //     title: '登录'
  //   }
  // }
]

export default routes
