/*
 * @Description: 数据字典接口文件
 * @Author: Wallis
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: tzw 546419247@qq.com
 * @LastEditTime: 2023-04-19 16:55:21
 */
import request from '@/plugin/axios'

/**
 * @description 添加数据字典
 * @param {Object} obj 参数列表
 */
export function _AddDict(obj) {
  return request({
    url: '/admin/dict/',
    method: 'post',
    data: obj
  })
}
/**
 * @description 添加字典项数据
 * @param {Object} obj 参数列表
 */
export function _AddDictItem(obj) {
  return request({
    url: '/admin/dict/item',
    method: 'post',
    data: obj
  })
}

/**
 * @description 根据ID删除字典数据
 * @param {Object} row 当前行数据
 */
export function _DelDict(row) {
  return request({
    url: '/admin/dict/' + row.id,
    method: 'delete'
  })
}

/**
 * @description 根据ID删除字典项数据
 * @param {Number} id 字典ID
 */
export function _DelDictItem(id) {
  return request({
    url: '/admin/dict/item/' + id,
    method: 'delete'
  })
}

/**
 * @description 更新字典数据
 * @param {Object} obj 提交表单数据
 */
export function _PutDict(obj) {
  // 更新字典信息
  return request({
    url: '/admin/dict/',
    method: 'put',
    data: obj
  })
}

/**
 * @description 更新字典项数据
 * @param {Object} obj 字典项表单数据
 */
export function _PutDictItem(obj) {
  //
  return request({
    url: '/admin/dict/item',
    method: 'put',
    data: obj
  })
}

/**
 * @description 根据ID获取字典数据
 * @param {Number} id 字典 ID
 */
export function _GetDict(id) {
  return request({
    url: '/admin/dict/' + id,
    method: 'get'
  })
}
/**
 * @description 根据ID获取字典项数据
 * @param {Number} id 字典ID
 */
export function _GetDictItem(id) {
  return request({
    url: '/admin/dict/item/' + id,
    method: 'get'
  })
}

/**
 * @description 根据 type 获取数据字典数据
 * @param {String} type 字典类型
 */
export function _Remote(type) {
  return request({
    url: '/admin/dict/type/' + type,
    method: 'get'
  })
}

/**
 * @description 获取字典项数据列表
 * @param {Object} query 参数列表
 */
export function _GetDictItemList(query) {
  return request({
    url: '/admin/dict/item/list',
    method: 'get',
    params: query
  })
}

/**
 * @description 获取数据字典分页数据
 * @param {Object} query 分页数据
 * @param {Object} query 查询参数
 */
export function _GetDictPage(page, query) {
  const params = {
    ...page,
    ...query
  }
  return request({
    url: '/admin/dict/page',
    method: 'get',
    params: params
  })
}

/**
 * @description 获取字典项分页数据
 * @param {Object} page 分页对象
 * @param {Object} query 查询参数
 */
export function _GetDictItemPage(page, query) {
  const params = {
    ...page,
    ...query
  }
  return request({
    url: '/admin/dict/item/page',
    method: 'get',
    params: params
  })
}

// 导出字典 API
export default {
  _AddDict,
  _AddDictItem,
  _DelDict,
  _DelDictItem,
  _PutDict,
  _PutDictItem,
  _GetDict,
  _GetDictItem,
  _Remote,
  _GetDictItemList,
  _GetDictItemPage,
  _GetDictPage
}