/*
 * @Description: Security 工具类
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: zhangdiwa 15921845363@163.com
 * @LastEditTime: 2023-04-04 18:23:38
 */
import CryptoJS from 'crypto-js'
import setting from '@/setting'

const security = {}

/**
 * Base64 对象加密处理
 */
security.objectEncryption = (params) => {
  const {
    data,
    type,
    param
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      const key = CryptoJS.enc.Latin1.parse(setting.server.encryptionKey)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/**
 * Base64 加密处理
 */
security.encryption = (str) => {
  var key = CryptoJS.enc.Utf8.parse(setting.server.encryptionKey)
  var options = {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  }
  // 加密
  var encrypted = CryptoJS.AES.encrypt(str, key, options)
  // 对加密数据进行base64处理
  var encryptedBase64 = btoa(encrypted.toString())
  return encryptedBase64
}

/**
 * AES 加密
 * @param {*} src  明文
 * @param {*} keyWord  密钥
 * @returns 密文
 */
security.aesEncrypt = (src) => {
  const key = CryptoJS.enc.Utf8.parse(setting.server.encryptionKey)
  // 加密
  var encrypted = CryptoJS.AES.encrypt(src, key, {
    iv: key,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding
  })
  return encrypted.toString()
}
/**
 * Base64 解密处理
 */
security.decrypt = (encryptedStr) => {
  // 对加密数据进行base64解密处理
  var encryptedBase64Str = atob(encryptedStr)
  var encryptionKey = CryptoJS.enc.Utf8.parse(setting.server.encryptionKey)
  var options = {
    iv: encryptionKey,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  }
  // AES 解密
  var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, encryptionKey, options)
  // 解密后，需要按照Utf8的方式将明文转位字符串
  var decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8)
  return decryptedStr
}

export default security
