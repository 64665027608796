/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: yjk
 * @LastEditTime: 2023-06-01 10:30:30
 */
import request from "@/plugin/axios";
import QS from "qs";
import setting from "@/setting";

const SCOPE = "server";
const TENANT_ID = setting.server.tenantId;
const AUTHORIZATION = "Basic " + window.btoa(setting.server.defaultClient);

export function _LoginByAccount(data) {
  console.log("~~~~~~~~~:", data);
  const dataObj = QS.stringify({
    username: data.username,
    password: data.password,
  });
  const grantType = "password";
  return request({
    url: "/auth/oauth2/token",
    headers: {
      isToken: false,
      "TENANT-ID": TENANT_ID,
      Authorization: AUTHORIZATION,
    },
    method: "post",
    params: {
      randomStr: data.randomStr,
      code: data.code,
      grant_type: grantType,
      scope: SCOPE,
    },
    data: dataObj,
  });
}

export function _ReqGet(data) {
  return request({
    url: "/code/create",
    method: "get",
    data,
  });
}

export function _ReqCheck(data) {
  return request({
    url: "/code/check",
    method: "post",
    params: data,
  });
}

export function refreshToken(refreshToken) {
  const grantType = "refresh_token";
  return request({
    url: "/auth/oauth/token",
    headers: {
      isToken: false,
      "TENANT-ID": TENANT_ID,
      Authorization: AUTHORIZATION,
    },
    method: "post",
    params: {
      refresh_token: refreshToken,
      grant_type: grantType,
      scope: SCOPE,
    },
  });
}

export function _Logout() {
  return request({
    url: "/auth/token/logout",
    method: "delete",
  });
}

export function _GetUserInfo() {
  return request({
    url: "/app/front/appuser/info",
    method: "get",
  });
}
export function _GetUserMenu() {
  return request({
    url: "/admin/menu",
    method: "get",
  });
}

export function _RefreshToken(refreshToken) {
  const grantType = "refresh_token";
  return request({
    url: "/auth/oauth/token",
    headers: {
      isToken: false,
      "TENANT-ID": TENANT_ID,
      Authorization: AUTHORIZATION,
    },
    method: "post",
    params: {
      refresh_token: refreshToken,
      grant_type: grantType,
      scope: SCOPE,
    },
  });
}
// 获取登录验证码
export function _reqCode(data) {
  return request({
    url: "/app/front/appuser/login/code",
    data,
    method: "POST",
  });
}

// 获取手机号绑定账户列表
export function _reqAccountList(data) {
  console.log(data);
  return request({
    url: "/app/front/appuser/phone/account/list",
    params: data,
    method: "GET",
  });
}

export function _loginByPhone(data) {
  const dataObj = {
    mobile: `APP-SMS@${data.mobile},${data.username}`,
    code: data.code,
  };
  const grantType = "mobile";
  return request({
    url: "/auth/oauth2/token",
    headers: {
      isToken: false,
      "TENANT-ID": TENANT_ID,
      Authorization: AUTHORIZATION,
    },
    method: "post",
    params: {
      grant_type: grantType,
      scope: SCOPE,
      ...dataObj,
    },
  });
}

// 重新设置密码
export function _resetPassword(data) {
  return request({
    url: "/app/front/appuser/retireve/pwd",
    data,
    method: "POST",
  });
}

// 找回密码 验证邮箱/手机号是否与账户匹配
export function _checkEmailPhone(data) {
  return request({
    url: "/app/front/appuser/retireve/pwd/before",
    method: "POST",
    data,
  });
}
// 验证 邮箱/手机号与验证码是否匹配
export function _checkEmailPhoneCode(data) {
  return request({
    url: "/app/front/appuser/retireve/pwd/before/valid",
    data,
    method: "POST",
  });
}
