/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: tzw 546419247@qq.com
 * @LastEditTime: 2023-04-06 13:32:26
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store/index'
// 工具类
import util from '@/libs/util.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 获取 Token
  const token = util.getToken()
  // 已登录，用户信息为空，则重新载入用户信息
  if (token && token !== 'undefined' && util.isNull(store.state.user.info)) {
    store.dispatch('user/load').catch((err) => {
      this.$message.error(err.message)
      // 错误,跳转到登陆页面
      store.dispatch('account/logout', {
        vm: router.app
      })
    })
  }

  // 判断是否是用户中心页面
  if (to.fullPath.startsWith('/uc')) {
    // Token 不为空，说明已登录
    if (token && token !== 'undefined') {
      next()
    } else {
      // 如果访问页面是登陆页面,则直接放行
      if (to.path === '/login') {
        next()
      } else {
        // 跳转至登录页面
        next({
          name: 'login',
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  // 更改标题
  util.title(to.meta.title)
  window.scrollTo(0, 0)
})
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => err)
}
export default router