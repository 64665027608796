<!--
 * @Description: Vue 根组建
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: yjk
 * @LastEditTime: 2023-04-27 10:44:13
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('dict', ["init"]),
  },
};
</script>
<style lang="scss">
@import "~@/assets/style/public-class.scss";
html,
body,
#app {
  height: 100%;
}
</style>
