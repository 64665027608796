/*
 * @Description: 用一句话描述文件的作用
 * @Author: yjk
 * @Date: 2023-04-21 10:02:44
 * @LastEditors: yjk
 * @LastEditTime: 2023-04-21 10:05:58
 */
/**
 * 自定义过滤器
 * 过滤器说明:
 *   1,Vue2.x废弃了内置过滤器，允许自定义过滤器
 *   2,过滤器可以用在两个地方：双花括号插值和 v-bind 表达式 (后者从 2.1.0+ 开始支持)
 *   3,过滤器应该被添加在 JavaScript 表达式的尾部，由“管道”符号指示
 * 使用示例:
 *   1,双花括号插值: {{ message | capitalize }}
 *   2,v-bind 绑定: <div v-bind:id="rawId | formatId"></div>
 */
import util from "@/libs/util"; // 工具类

/**
 * 倒计时计算
 * @description 倒计时计算
 * @param {Object} value 结束时间
 */
let timeFilter = (value) => {
  return util.getEndTime(value)
}

export default {
  timeFilter,
};
