/*
 * @Description: 系统配置文件
 * @Author: Pancras
 * @Date: 2019-09-29 10:44:30
 * @LastEditors: yjk
 * @LastEditTime: 2023-05-06 19:55:12
 */
export default {
  webSite: {
    title: "阿瓦提县长绒棉现货交易中心",
    logo: "",
    copyright: "Copyright ©2022 阿瓦提县长绒棉现货交易中心 版权所有",
    suport: "新疆绿洲驼铃农业有限公司",
    phone: "400 380 3132",
    email: "MXBV@sina.cn",
    officeHours: "周一至周五 10:00-20:00",
    h5QrCodeTitle: "丰帮农服",
    h5QrCodeUrl:
      "http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png",
    wxQrCodeTitle: "丰帮小哥",
    wxQrCodeUrl:
      "http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png",
    appStewardTitle: "丰帮农服",
    appStewardQcode:
      "http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png",
    appLaddieTitle: "丰帮小哥",
    appLaddieQrCode:
      "http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png",
    address: "新疆阿克苏地区阿瓦提县北京路555号",
  },
  // 服务器配置
  server: {
    // 定义 BASE_URL
    baseUrl: process.env.VUE_APP_API,
    // // 加密用 key 应和服务器保持一致
    // encryptionKey: 'asspasspasspassp',
    // // 租户ID
    // tenantId: '1',
    // // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    // authorization: 'Basic YXdhdDphd2F0'

    // 加密用 key 应和服务器保持一致
    encryptionKey: "HyiJKfAiZuiFeDxt",
    // 租户ID
    tenantId: "1",
    // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    defaultClient: "web:web", // 用户名登录 Client
    smsClient: "app:app", // 验证码登录 Client
    socialClient: "social:social", // 社交登录 Client
  },
  transition: {
    active: true,
  },
};
