import dayjs from 'dayjs'
import util from '@/libs/util'
import sessions from '@/libs/util.sessions'

export default {
  namespaced: true,
  state: {
    log: JSON.parse(sessions.get('log')) || []
  },
  getters: {
    /**
     * @description 返回现存 lo (all) 的条数
     * @param {*} state vuex state
     */
    length (state) {
      return state.log.length
    },
    /**
     * @description 返回现存 log (error) 的条数
     * @param {*} state vuex state
     */
    lengthError (state) {
      return state.log.filter(log => log.type === '9').length
    }
  },
  actions: {
    /**
     * @description 添加一个日志
     * @param {Object} context
     * @param {String} type 类型 0:正常 9:异常
     * @param {String} message 错误信息
     * @param {String} stack   错误栈
     * @param {Object} info    附带的信息
     */
    push ({ commit }, { url, method, params, type, message, stack, info }) {
      commit('push', {
        url: url || window.location.href,
        method: method || 'get',
        params: JSON.stringify(params),
        time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        type,
        message,
        stack,
        info: !util.isNull(info) ? info.toString() : message
      })
    },

    /**
     * @description 清空日志
     * @param {Object} context
     */
    clean ({ commit }) {
      commit('clean')
    }
  },
  mutations: {
    /**
     * @description 添加日志
     * @param {Object} state state
     * @param {Object} log data
     */
    push (state, log) {
      state.log.push(log)
      sessions.set('log', JSON.stringify(state.log))
    },
    /**
     * @description 清空日志
     * @param {Object} state state
     */
    clean (state) {
      // store 赋值
      state.log = []
      sessions.set('log', null)
    }
  }
}
